import { Routes, Route, useLocation } from "react-router-dom";
import { useContext, useLayoutEffect } from "react";
import { AuthContext } from "./context/auth-context";
import BookingsPage from "./components/bookings-page/Index";
import Layout from "./components/layout/Layout";
import NotFound from "./components/404";
import CustomersPage from "./components/customers-page/Index";
import VehiclesPage from "./components/vehicles-page/Index";
import GeneralPage from "./components/general-page/Index";
import Expenses from "./components/expenses-page/index";
import LoginPage from "./components/LoginPage";
import CustomerInfo from "./components/customers-page/CustomerInfo";
import VehicleInfo from "./components/vehicles-page/VehicleInfo";
import BookingInfo from "./components/bookings-page/BookingInfo";
import AddVehicle from "./components/vehicles-page/AddVehicle";
import AddBooking from "./components/bookings-page/AddBooking";
import PaymentsPage from "./components/payments-page/Index";
import PaymentInfo from "./components/payments-page/PaymentInfo";
import AddPayment from "./components/payments-page/AddPayment";
import Tolls from "./components/tolls-page/index";
import BookingTolls from "./components/tolls-page/BookingTolls";
import Charging from "./components/charging-page/Index";
import AddCharging from "./components/charging-page/AddCharging";
import BookingCharging from "./components/charging-page/BookingCharging";
import LoadingSpinnerWrapper from "./components/UI/LoadingSpinnerWrapper";
import AddExpense from "./components/expenses-page/AddExpense";
import ExpenseInfo from "./components/expenses-page/ExpenseInfo";
import Partners from "./components/partner-page/Partners";
import PartnerInfo from "./components/partner-page/PartnerInfo";
import PartnerVehicle from "./components/partner-page/PartnerVehicle";
import Invoices from "./components/invoices-page/Index";
import AddSubscription from "./components/customers-page/AddSubscription";
import Brands from "./components/vehicles-page/Brands";
import CouponsPage from "./components/coupons-page/Index";
import CouponInfo from "./components/coupons-page/CouponInfo";
import AddCoupon from "./components/coupons-page/AddCoupon";
import Leads from "./components/customers-page/Leads";
import LeadInfo from "./components/customers-page/LeadInfo";
import Locations from "./components/locations-page";
import LocationInfo from "./components/locations-page/LocationInfo";
import AddLocation from "./components/locations-page/AddLocation";
import VehicleReport from "./components/vehicles-page/VehicleReport";
import Employees from "./components/employees-page/Index";
import EmployeeInfo from "./components/employees-page/EmployeeInfo";
import AddEmployee from "./components/employees-page/AddEmployee";
import CustomerPayments from "./components/customers-page/CustomerPayment";
import PartnerReports from "./components/partner-page/PartnerReports";
import FBDCTesting from "./components/fbdc-testing";

function App() {
  const { user, loadingUser } = useContext(AuthContext);
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0, { behavior: "smooth" });
  }, [location.pathname]);

  if (loadingUser)
    return (
      <div className="flex items-center justify-center w-full h-screen bg-stone-900">
        <LoadingSpinnerWrapper />
      </div>
    );
  else if (user)
    return (
      <Layout>
        <Routes>
          <Route path="/" element={<GeneralPage />} />
          <Route path="/general" element={<GeneralPage />} />

          <Route path="/bookings" element={<BookingsPage />} />
          <Route path="/bookings/info" element={<BookingInfo />} />
          <Route path="/bookings/add" element={<AddBooking />} />

          <Route path="/customers" element={<CustomersPage />} />
          <Route path="/customers/info" element={<CustomerInfo />} />
          <Route path="/customers/payments" element={<CustomerPayments />} />
          <Route
            path="/customers/add-subscription"
            element={<AddSubscription />}
          />

          <Route path="/leads" element={<Leads />} />
          <Route path="/leads/info" element={<LeadInfo />} />

          <Route path="/partners" element={<Partners />} />
          <Route path="/partners/info" element={<PartnerInfo />} />
          <Route path="/partners/vehicle" element={<PartnerVehicle />} />
          <Route path="/partners/report" element={<PartnerReports />} />

          <Route path="/vehicles" element={<VehiclesPage />} />
          <Route path="/vehicles/info" element={<VehicleInfo />} />
          <Route path="/vehicles/add" element={<AddVehicle />} />
          <Route path="/vehicles/report" element={<VehicleReport />} />

          <Route path="/brands" element={<Brands />} />

          <Route path="/payments" element={<PaymentsPage />} />
          <Route path="/payments/info" element={<PaymentInfo />} />
          <Route path="/payments/add" element={<AddPayment />} />

          <Route path="/coupons" element={<CouponsPage />} />
          <Route path="/coupons/info" element={<CouponInfo />} />
          <Route path="/coupons/add" element={<AddCoupon />} />

          <Route path="/invoices" element={<Invoices />} />

          <Route path="/tolls" element={<Tolls />} />
          <Route path="/tolls/booking" element={<BookingTolls />} />

          <Route path="/charging" element={<Charging />} />
          <Route path="/charging/add" element={<AddCharging />} />
          <Route path="/charging/booking" element={<BookingCharging />} />

          <Route path="/expenses" element={<Expenses />} />
          <Route path="/expenses/add" element={<AddExpense />} />
          <Route path="/expenses/info" element={<ExpenseInfo />} />

          <Route path="/locations" element={<Locations />} />
          <Route path="/locations/info" element={<LocationInfo />} />
          <Route path="/locations/add" element={<AddLocation />} />

          <Route path="/employees" element={<Employees />} />
          <Route path="/employees/info" element={<EmployeeInfo />} />
          <Route path="/employees/add" element={<AddEmployee />} />

          <Route path="/fbdc" element={<FBDCTesting />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    );
  else return <LoginPage />;
}

export default App;
